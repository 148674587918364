<template>
	<div
		v-if="organisationName"
		class="OrganisationDetailsBlock"
	>
		<h2 class="UsersManagementTitle">
			{{ organisationName }}
		</h2>
		<span class="UsersManagementLabel">
			Organisation ID:
		</span>
		<span class="UsersManagementIdBlock">
			{{ organisationId }}
		</span>
	</div>
</template>

<style lang="scss" src="./organisation-details-block.scss"></style>
<script lang="ts" src="./organisation-details-block.ts"></script>
