import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "UserDetailsBlock" }
const _hoisted_2 = { class: "UserDetailsBlockColumn" }
const _hoisted_3 = { class: "UserDetailsBlockTitle" }
const _hoisted_4 = {
  key: 0,
  class: "UserDetailsBlockRow"
}
const _hoisted_5 = { class: "UserDetailsBlockRow" }
const _hoisted_6 = { class: "UserDetailsBlockRow" }
const _hoisted_7 = { class: "UserDetailsBlockColumn" }
const _hoisted_8 = { class: "UserDetailsBlockRow" }
const _hoisted_9 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.selectedUserName), 1 /* TEXT */),
      (_ctx.selectedUserRole)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _cache[1] || (_cache[1] = _createElementVNode("label", { class: "UserDetailsBlockLabel" }, " Role: ", -1 /* HOISTED */)),
            _createTextVNode(" " + _toDisplayString(_ctx.selectedUserRole), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_5, [
        _cache[2] || (_cache[2] = _createElementVNode("label", { class: "UserDetailsBlockLabel" }, " Products: ", -1 /* HOISTED */)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productTags, (tag) => {
          return (_openBlock(), _createElementBlock("div", {
            key: tag,
            class: "CustomComponentTag"
          }, _toDisplayString(tag), 1 /* TEXT */))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[3] || (_cache[3] = _createElementVNode("label", { class: "UserDetailsBlockLabel" }, " Email: ", -1 /* HOISTED */)),
        _createTextVNode(" " + _toDisplayString(_ctx.selectedUserEmail), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _cache[4] || (_cache[4] = _createElementVNode("label", { class: "UserDetailsBlockLabel LastLoginLabel" }, " Last login: ", -1 /* HOISTED */)),
        _createElementVNode("span", {
          class: "UserDetailsBlockValue",
          title: _ctx.lastLoginDateStringLong
        }, _toDisplayString(_ctx.lastLoginDateStringShort), 9 /* TEXT, PROPS */, _hoisted_9),
        _createElementVNode("i", {
          class: "UserDetailsBlockIcon UserDetailsBlockEditButton lnr-pencil",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeUserDetailsForm && _ctx.closeUserDetailsForm(...args)))
        }),
        _cache[5] || (_cache[5] = _createElementVNode("i", { class: "UserDetailsBlockIcon UserDetailsBlockDisabledIcon lnr-trash2" }, null, -1 /* HOISTED */)),
        _cache[6] || (_cache[6] = _createElementVNode("i", { class: "UserDetailsBlockIcon UserDetailsBlockDisabledIcon lnr-unlink" }, null, -1 /* HOISTED */))
      ])
    ])
  ]))
}