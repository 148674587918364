import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "SearchIcon"
}
const _hoisted_2 = {
  class: "UserSearchWrapper",
  ref: "searchWrapper"
}
const _hoisted_3 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchDropdown = _resolveComponent("SearchDropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
			'SearchContainer',
			{ SpecificSearchContainer: _ctx.isSearchTypeUser || _ctx.isSearchTypeOrganisation },
			{ CombinedSearchContainer: _ctx.isSearchTypeCombinedSearch }
		])
  }, [
    (_ctx.isSearchTypeCombinedSearch)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _cache[2] || (_cache[2] = [
          _createElementVNode("i", { class: "lnr-magnifier" }, null, -1 /* HOISTED */)
        ])))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        type: "text",
        class: "SearchInput",
        ref: "searchInput",
        placeholder: _ctx.placeholder,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setSearchTerm && _ctx.setSearchTerm(...args))),
        onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInputFocus && _ctx.onInputFocus(...args)))
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_3),
      (_ctx.showDropdown)
        ? (_openBlock(), _createBlock(_component_SearchDropdown, {
            key: _ctx.searchResultsAsString,
            type: _ctx.type,
            searchTerm: _ctx.searchTerm,
            items: _ctx.searchResults,
            showSpinner: _ctx.showSpinner,
            onUserClick: _ctx.onUserClick
          }, null, 8 /* PROPS */, ["type", "searchTerm", "items", "showSpinner", "onUserClick"]))
        : _createCommentVNode("v-if", true)
    ], 512 /* NEED_PATCH */)
  ], 2 /* CLASS */))
}