enum SpecificErrorKeywords {
	DUPLICATE_ENTRY = 'Duplicate entry'
}

interface HttpErrorResponse extends Error {
	response?: {
		text?: string;
	};
}

export { HttpErrorResponse, SpecificErrorKeywords };
