import { defineComponent } from 'vue';
import OrganisationDetailsBlockCode from '@/presentation/components/organisation-details-block/organisation-details-block.code';

export default defineComponent({
	setup: () => {
		const code = new OrganisationDetailsBlockCode();

		return {
			organisationId: code.organisationId,
			organisationName: code.organisationName
		};
	}
});
