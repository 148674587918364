import { computed, ref } from 'vue';
import { SearchDropdownTypes } from '@/models/search.interface';
import { DetailedUserDto } from '@studyportals/campaign-management-api-interface';
import { ModalConfirmationStates } from '@/models/modal/modal.enum';
import { Routes } from '@/models/router/router.interface';
import organisationsPresenter from '@/presenters/organisations-presenter';
import userPresenter from '@/presenters/user-presenter';
import store from '@/store';

export default class OrganisationCode {
	public isNewUserAccordionOpen = ref(false);

	public get searchTypeUser(): SearchDropdownTypes {
		return SearchDropdownTypes.USER;
	}

	public amountOfUsersText = computed((): string => {
		const count = this.organisationUsers.value.length;
		return `${count} user${count === 1 ? '' : 's'}`;
	});

	public isModalConfirmationStateDelete = computed((): boolean => {
		return this.modalConfirmationState.value === ModalConfirmationStates.DELETE_USER;
	});

	private routerOrganisationId = computed((): number => {
		const router = store.getters.router();
		return router.props.id;
	});

	private organisationUsers = computed((): DetailedUserDto[] => {
		return store.getters.organisationUsers();
	});

	private modalConfirmationState = computed((): ModalConfirmationStates => {
		return store.getters.modalConfirmationState();
	});

	public mounted(): void {
		void organisationsPresenter.prepareOrganisationDetails(this.routerOrganisationId.value);
		void userPresenter.prepareUsersAttachedToOrganisation(this.routerOrganisationId.value);
	}

	public toggleNewUserAccordion(): void {
		this.isNewUserAccordionOpen.value = !this.isNewUserAccordionOpen.value;
	}

	public navigateToUserActionsPage(): void {
		store.mutations.setCurrentRoute(Routes.USER_ACTIONS, this.routerOrganisationId.value);
	}
}
