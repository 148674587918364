/* eslint-disable jsdoc/tag-lines */

import { computed, ref, Ref } from 'vue';
import { createGrid, GridOptions, GridApi, ColDef, ModuleRegistry, AllCommunityModule, provideGlobalGridOptions } from 'ag-grid-community';
import { ACCORDION_ANIMATION_DURATION, BUFFER_DURATION } from '@/static/timing';
import { DetailedUserDto } from '@studyportals/campaign-management-api-interface';
import CustomComponentName from '@/presentation/components/organisation-users-table/custom-component-name/custom-component-name';
import CustomComponentCampaigns
	from '@/presentation/components/organisation-users-table/custom-component-campaigns/custom-component-campaigns';
import CustomComponentProducts
	from '@/presentation/components/organisation-users-table/custom-component-products/custom-component-products';
import CustomComponentActions from '@/presentation/components/organisation-users-table/custom-component-actions/custom-component-actions';
import CustomComponentNameTooltip
	from '@/presentation/components/organisation-users-table/custom-component-name-tooltip/custom-component-name-tooltip';
import CustomComponentHeader from '@/presentation/components/organisation-users-table/custom-component-header/custom-component-header';
import UserTableFields from '@/presentation/components/organisation-users-table/organisation-users-table-fields';
import store from '@/store';

provideGlobalGridOptions({ theme: 'legacy' });
ModuleRegistry.registerModules([
	AllCommunityModule
]);

export default class OrganisationUsersTableCode {
	public topGrid: Ref<HTMLElement | null> = ref(null);
	public topGridObject: Ref<GridApi | null> = ref(null);
	public bottomGrid: Ref<HTMLElement | null> = ref(null);
	public bottomGridObject: Ref<GridApi | null> = ref(null);
	public fields = UserTableFields;
	/** Same as selectedUserAsStored, but updated with a delay to allow a closing animation to finish.
	 * @see selectedUserAsStored
	 * @see selectedUserForDisplayPurposes */
	private selectedUserUpdatedWithDelay = ref(0);
	/** Same as indexOfSelectedUserAsStored, but updated with a delay to allow a closing animation to finish.
	 * @see indexOfSelectedUserAsStored
	 * @see selectedUserForDisplayPurposes */
	private indexOfSelectedUserUpdatedWithDelay = ref(-1);
	private customComponents = {
		CustomComponentName,
		CustomComponentCampaigns,
		CustomComponentProducts,
		CustomComponentActions,
		CustomComponentNameTooltip,
		agColumnHeader: CustomComponentHeader
	};
	private boundUpdateOrganisationUsersSorted: () => void;

	/** Gives either the stored or delayed selected user value depending on whether an animation is still active. */
	public selectedUserForDisplayPurposes = computed((): number => {
		if (this.selectedUserUpdatedWithDelay.value !== 0) {
			return this.selectedUserUpdatedWithDelay.value;
		}

		return this.selectedUserAsStored.value;
	});

	/** Gives either the stored or delayed selected user index value depending on whether an animation is still active. */
	public indexOfSelectedUserForDisplayPurposes = computed((): number => {
		if (this.indexOfSelectedUserUpdatedWithDelay.value !== -1) {
			return this.indexOfSelectedUserUpdatedWithDelay.value;
		}

		return this.indexOfSelectedUserAsStored.value;
	});

	public topOrganisationUsers = computed((): DetailedUserDto[] => {
		const organisationUsers = this.organisationUsersSorted.value;
		const index = this.indexOfSelectedUserForDisplayPurposes.value;
		if (index === -1) {
			return organisationUsers;
		}

		// Extract only the relevant part of the array, in a way that doesn't mutate the original array.
		return [...organisationUsers].slice(0, index + 1);
	});

	public bottomOrganisationUsers = computed((): DetailedUserDto[] => {
		const organisationUsers = this.organisationUsersSorted.value;
		const index = this.indexOfSelectedUserForDisplayPurposes.value;
		if (index === -1) {
			return [];
		}

		// Extract only the relevant part of the array, in a way that doesn't mutate the original array.
		return [...organisationUsers].slice(index + 1);
	});

	public detailsOfSelectedUser = computed((): DetailedUserDto | undefined => {
		if (this.indexOfSelectedUserForDisplayPurposes.value === -1) {
			return;
		}

		return this.organisationUsersSorted.value[this.indexOfSelectedUserForDisplayPurposes.value];
	});

	public shouldSecondTableBeShown = computed((): boolean => {
		return this.selectedUserForDisplayPurposes.value !== 0
			&& this.indexOfSelectedUserForDisplayPurposes.value !== -1
			&& this.indexOfSelectedUserForDisplayPurposes.value < this.organisationUsers.value.length - 1;
	});

	public organisationUsers = computed((): DetailedUserDto[] => {
		return store.getters.organisationUsers();
	});

	public organisationUsersSorted = computed((): DetailedUserDto[] => {
		return store.getters.organisationUsersSorted();
	});

	/** The selected user as it is stored in the reactive store. */
	public selectedUserAsStored = computed((): number => {
		return store.getters.selectedUser();
	});

	/** Index of the selected user (as stored in reactive store) in the list of organisation users. */
	private indexOfSelectedUserAsStored = computed((): number => {
		return this.organisationUsersSorted.value.findIndex(
			(user) => user.id === this.selectedUserForDisplayPurposes.value
		);
	});

	constructor() {
		this.boundUpdateOrganisationUsersSorted = this.updateOrganisationUsersSorted.bind(this);
	}

	public mounted(): void {
		if (!this.topGrid.value || !this.bottomGrid.value) {
			return;
		}

		this.topGridObject.value = createGrid(
			this.topGrid.value,
			this.constructGridOptions(true)
		);

		this.bottomGridObject.value = createGrid(
			this.bottomGrid.value,
			this.constructGridOptions(false)
		);

		this.initiateTableRefresh();
		// Reset in case a user was selected on a previous page.
		this.resetSelectedUser();

		document.addEventListener('UsersTableSortChanged', this.boundUpdateOrganisationUsersSorted);
	}

	public unmounted(): void {
		document.removeEventListener('UsersTableSortChanged', this.boundUpdateOrganisationUsersSorted);
	}

	public initiateTableRefresh(): void {
		if (this.selectedUserAsStored.value) {
			this.refreshTable();
		} else {
			setTimeout(() => {
				this.refreshTable();
			}, ACCORDION_ANIMATION_DURATION);
		}
	}

	private refreshTable(): void {
		this.selectedUserUpdatedWithDelay.value = this.selectedUserAsStored.value;
		this.indexOfSelectedUserUpdatedWithDelay.value = this.organisationUsersSorted.value.findIndex(
			(user) => user.id === this.selectedUserAsStored.value
		);

		if (this.topGridObject.value) {
			this.topGridObject.value.setGridOption('rowData', this.topOrganisationUsers.value);
		}

		if (this.bottomGridObject.value) {
			this.bottomGridObject.value.setGridOption('rowData', this.bottomOrganisationUsers.value);
		}

		this.boundUpdateOrganisationUsersSorted();
	}

	public resetSelectedUser(): void {
		store.mutations.setSelectedUser(0);
	}

	private constructGridOptions(isTopGrid: boolean): GridOptions {
		return {
			columnDefs: this.fields as ColDef[],
			components: this.customComponents,
			domLayout: 'autoHeight',
			headerHeight: isTopGrid ? 40 : 0,
			onFirstDataRendered: this.initiateTableRefresh.bind(this),
			onRowValueChanged: this.initiateTableRefresh.bind(this),
			rowData: [],
			tooltipShowDelay: 0,
			animateRows: true,
			suppressHorizontalScroll: true
		} as unknown as GridOptions;
	}

	private updateOrganisationUsersSorted(): void {
		setTimeout(() => {
			store.mutations.updateOrganisationUsersSorted(this.retrieveOrganisationUsersShownInTable());
		}, ACCORDION_ANIMATION_DURATION + BUFFER_DURATION);
	}

	private retrieveOrganisationUsersShownInTable(): DetailedUserDto[] {
		if (!this.topGridObject.value || !this.bottomGridObject.value) {
			return [];
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		const topCells = this.topGridObject.value.getRenderedNodes().map((node) => node.data);
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		const bottomCells = this.bottomGridObject.value.getRenderedNodes().map((node) => node.data);
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
		return [...topCells, ...bottomCells];
	}
}
