import { computed } from 'vue';
import { MinimalOrganisationDto } from '@studyportals/campaign-management-api-interface';
import store from '@/store';

export default class OrganisationDetailsBlockCode {
	public organisationId = computed((): string => {
		return this.organisationDetails.value?.id.toString() || '';
	});

	public organisationName = computed((): string => {
		return this.organisationDetails.value?.name || '';
	});

	private organisationDetails = computed((): MinimalOrganisationDto | null => {
		return store.getters.organisationDetails();
	});
}
