import { defineComponent, onMounted, onUnmounted } from 'vue';
import ErrorModalCode from '@/presentation/components/error-modal/error-modal.code';

export default defineComponent({
	setup: () => {
		const code = new ErrorModalCode();

		onMounted(code.mounted.bind(code));
		onUnmounted(code.unmounted.bind(code));

		return {
			modalContent: code.modalContent,
			errorIsRelatedToOrganisationDetails: code.errorIsRelatedToOrganisationDetails,
			errorIsRelatedToOrganisationUsers: code.errorIsRelatedToOrganisationUsers,
			errorIsRelatedToCreateNewUser: code.errorIsRelatedToCreateNewUser,
			errorIsRelatedToUpdateExistingUser: code.errorIsRelatedToUpdateExistingUser,
			errorIsRelatedToTriggerOnboardingFlow: code.errorIsRelatedToTriggerOnboardingFlow,
			errorIsRelatedToSearchUsers: code.errorIsRelatedToSearchUsers,
			errorIsRelatedToAttachUserToOrganisation: code.errorIsRelatedToAttachUserToOrganisation,
			errorIsRelatedToDeleteUser: code.errorIsRelatedToDeleteUser,
			errorIsRelatedToDetachUser: code.errorIsRelatedToDetachUser,
			errorIsRelatedToSearchOrganisations: code.errorIsRelatedToSearchOrganisations,
			errorIsRelatedToCombinedSearchUsers: code.errorIsRelatedToCombinedSearchUsers,
			errorIsRelatedToCreateDuplicateUser: code.errorIsRelatedToCreateDuplicateUser
		};
	}
});
