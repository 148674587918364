<template>
	<div class="UsersManagementAccordion">
		<button
			class="UsersManagementButton"
			@click="toggleAccordion"
		>
			<span class="NavigatorButton UsersManagementButtonIcon">
				<i :class="isAccordionOpen ? 'lnr-minus' : 'lnr-plus'"></i>
			</span>
			<span
				class="UsersManagementButtonText"
				v-html="buttonText"
			></span>
		</button>
		<div :class="[
			'UsersManagementAccordionContent',
			{ ContentVisible: isAccordionOpen }
		]">
			<slot />
		</div>
	</div>
</template>

<script lang="ts" src="./accordion.ts"></script>
<style lang="scss" src="./accordion.scss"></style>
