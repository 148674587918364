import { computed } from 'vue';
import { NOTIFICATION_DISPLAY_DURATION, NOTIFICATION_DISPLAY_DURATION_AFTER_ROUTING } from '@/static/timing';
import { DetailedUserDto, UserCampaignManagementAPIClient } from '@studyportals/campaign-management-api-interface';
import { SearchUserWithOrganisationDto }
	from '@studyportals/campaign-management-api-interface/src/domain/users/search-user-with-organisation.dto';
import { SearchUserDto } from '@studyportals/campaign-management-api-interface/src/domain/users/search-users.dto';
import { CreateClientDto } from '@studyportals/campaign-management-api-interface/src/domain/users/create-client.dto';
import { UpdateClientDto } from '@studyportals/campaign-management-api-interface/src/domain/users/update-client.dto';
import { ISearchResult, SearchResultTypes } from '@/models/search.interface';
import { ModalErrorStates } from '@/models/modal/modal.enum';
import { HttpErrorResponse, SpecificErrorKeywords } from '@/models/error.interface';
import cookieHandler from '@/infrastructure/cookie-handler';
import rollbarOperator from '@/infrastructure/rollbar-operator';
import store from '@/store';

class UserPresenter {
	private client: UserCampaignManagementAPIClient;
	private nameOfBackEndContext = 'UserRepository.createClientUser';
	private readonly tokenId: string;
	private readonly limit = 50;

	private routerOrganisationId = computed((): number => {
		const router = store.getters.router();
		return router.props.id;
	});

	private nameOfSelectedUser = computed((): string => {
		const users = this.organisationUsers.value;
		const selectedUser = this.selectedUser.value;
		const relevantUser = users.find((user: DetailedUserDto) => user.id === selectedUser);
		return relevantUser ? relevantUser.fullName : '';
	});

	private organisationUsers = computed((): DetailedUserDto[] => {
		return store.getters.organisationUsers();
	});

	private selectedUser = computed((): number => {
		return store.getters.selectedUser();
	});

	constructor() {
		this.client = new UserCampaignManagementAPIClient(webpackDefinitions.CAMPAIGN_MANAGEMENT_API_URL);
		this.tokenId = cookieHandler.retrieveTokenIdCookie();
	}

	public async createNewUser(user: CreateClientDto, organisationId?: number): Promise<void> {
		let id = 0;
		try {
			id = await this.client.createClient(user, this.tokenId);
		} catch (e) {
			this.handleUserCreationError(e as HttpErrorResponse);
			return;
		}

		try {
			// TODO: Use valid endpoint to trigger onboarding flow.
			// await (this.client as any).triggerOnboardingFlow(user, this.tokenId);
		} catch (e) {
			rollbarOperator.triggerError(e as Error);
			store.mutations.setModalErrorState(ModalErrorStates.TRIGGER_ONBOARDING_FLOW);
			return;
		}

		const organisationIdToAttachTo = organisationId || this.routerOrganisationId.value;
		await this.attachUserToOrganisation(id, user.fullName, organisationIdToAttachTo);
		await this.prepareUsersAttachedToOrganisation(organisationIdToAttachTo);

		this.showAddSuccessMessage(user.fullName);
	}

	public async updateExistingUser(user: UpdateClientDto): Promise<void> {
		try {
			await this.client.updateClient(user, this.tokenId);
		} catch (e) {
			rollbarOperator.triggerError(e as Error);
			store.mutations.setModalErrorState(ModalErrorStates.UPDATE_EXISTING_USER);
			return;
		}

		await this.prepareUsersAttachedToOrganisation(this.routerOrganisationId.value);

		this.showUpdateSuccessMessage(user.fullName);
		this.hideUserDetailsForm();
	}

	public async deleteUser(): Promise<void> {
		// Remember the name, as later on it cannot be looked up in the list of organisationUsers anymore.
		const name = this.nameOfSelectedUser.value;
		try {
			await this.client.deleteClient({ id: this.selectedUser.value }, this.tokenId);
		} catch (e) {
			rollbarOperator.triggerError(e as Error);
			store.mutations.setModalErrorState(ModalErrorStates.DELETE_USER);
			return;
		}

		await this.prepareUsersAttachedToOrganisation(this.routerOrganisationId.value);

		this.showDeletionSuccessMessage(name);
		this.hideUserDetailsForm();
	}

	public async detachUserFromOrganisation(): Promise<void> {
		// Remember the name, as later on it cannot be looked up in the list of organisationUsers anymore.
		const name = this.nameOfSelectedUser.value;
		try {
			await this.client.detachFromOrganisation(this.selectedUser.value, this.routerOrganisationId.value, this.tokenId);
		} catch (e) {
			rollbarOperator.triggerError(e as Error);
			store.mutations.setModalErrorState(ModalErrorStates.DETACH_USER);
			return;
		}

		await this.prepareUsersAttachedToOrganisation(this.routerOrganisationId.value);

		this.showDetachingSuccessMessage(name);
		this.hideUserDetailsForm();
	}

	public async searchUsers(searchTerm: string): Promise<ISearchResult[]> {
		let result: SearchUserDto[] = [];
		const existingUsers = this.organisationUsers.value;
		try {
			// TODO: Filter out already-attached users on BE side so that the limit is applied at the right moment.
			const response = await this.client.searchUsers(searchTerm, this.tokenId, this.limit);
			const filteredResponse = response.filter(
				(user) => !existingUsers.some((existingUser) => existingUser.id === user.id)
			);
			result = filteredResponse.slice(0, 10);
		} catch (e) {
			rollbarOperator.triggerError(e as Error);
			store.mutations.setModalErrorState(ModalErrorStates.SEARCH_USERS);
		}

		return result.map((item) => ({
			name: item.name,
			type: SearchResultTypes.USER,
			linkId: item.id,
			primarySubtitle: item.email
		}));
	}

	public async searchUsersForCombinedSearch(searchTerm: string): Promise<ISearchResult[]> {
		let result: SearchUserWithOrganisationDto[] = [];
		try {
			result = await this.client.searchClientsWithOrganisationsAttached(searchTerm, this.tokenId, this.limit);
		} catch (e) {
			rollbarOperator.triggerError(e as Error);
			store.mutations.setModalErrorState(ModalErrorStates.COMBINED_SEARCH_USERS);
		}

		return result.map((item) => ({
			name: item.name,
			type: item.organisationId ? SearchResultTypes.USER_FOR_COMBINED_SEARCH : SearchResultTypes.UNLINKED_USER_FOR_COMBINED_SEARCH,
			linkId: item.organisationId || undefined,
			primarySubtitle: item.email,
			secondarySubtitle: item.organisationName || undefined
		}));
	}

	public async attachUserToOrganisation(userId: number, name: string, organisationId?: number): Promise<void> {
		const organisationIdToAttachTo = organisationId || this.routerOrganisationId.value;
		try {
			await this.client.attachToOrganisation(userId, organisationIdToAttachTo, this.tokenId);
		} catch (e) {
			store.mutations.setModalErrorState(ModalErrorStates.ATTACH_USER_TO_ORGANISATION);
			rollbarOperator.triggerError(e as Error);
			return;
		}

		this.showAddSuccessMessage(name);
		await this.prepareUsersAttachedToOrganisation(organisationIdToAttachTo);
	}

	public async prepareUsersAttachedToOrganisation(organisationID: number): Promise<void> {
		try {
			const users = await this.client.getDetailedUsersByOrganisationID(organisationID, this.tokenId);
			store.mutations.setOrganisationUsers(users);
		} catch (e) {
			rollbarOperator.triggerError(e as Error);
			store.mutations.setModalErrorState(ModalErrorStates.LOADING_ORGANISATION_USERS);
		}
	}

	private handleUserCreationError(error: HttpErrorResponse): void {
		const specificErrorKeywords = Object.values(SpecificErrorKeywords);
		const errorResponse = JSON.parse(error.response?.text ?? '{ "message": "" }') as Error;
		const errorRelatedToSpecificScenarioProperty = specificErrorKeywords.some((message) => {
			return errorResponse.message.includes(this.nameOfBackEndContext) && errorResponse.message.includes(message);
		});

		if (!errorRelatedToSpecificScenarioProperty) {
			rollbarOperator.triggerError(error as Error);
			store.mutations.setModalErrorState(ModalErrorStates.CREATE_NEW_USER);
			return;
		}

		this.setModalErrorStateBasedOnPredefinedKeywords(errorResponse.message);
	}

	private setModalErrorStateBasedOnPredefinedKeywords(errorMessage: string): void {
		const keywordRelevantForError = Object.values(SpecificErrorKeywords).find((keyword) => errorMessage.includes(keyword));
		switch (keywordRelevantForError) {
			case SpecificErrorKeywords.DUPLICATE_ENTRY:
				store.mutations.setModalErrorState(ModalErrorStates.CREATE_DUPLICATE_USER);
				break;
			default:
				throw new Error(
					'UserPresenter.setModalErrorStateBasedOnPredefinedKeywords: error keyword not covered.'
				);
		}
	}

	private hideUserDetailsForm(): void {
		store.mutations.setSelectedUser(0);
	}

	private showAddSuccessMessage(name: string, messageShownAfterRouting?: boolean): void {
		store.mutations.setOrganisationSuccessMessage(`<em>${name}</em> was successfully added to this entity.`);
		setTimeout(() => {
			store.mutations.setOrganisationSuccessMessage('');
		}, messageShownAfterRouting ? NOTIFICATION_DISPLAY_DURATION_AFTER_ROUTING : NOTIFICATION_DISPLAY_DURATION);
	}

	private showUpdateSuccessMessage(name: string): void {
		store.mutations.setOrganisationSuccessMessage(`<em>${name}</em> was successfully updated.`);
		setTimeout(() => {
			store.mutations.setOrganisationSuccessMessage('');
		}, NOTIFICATION_DISPLAY_DURATION);
	}

	private showDeletionSuccessMessage(name: string): void {
		store.mutations.setOrganisationSuccessMessage(`<em>${name}</em> was successfully deleted.`);
		setTimeout(() => {
			store.mutations.setOrganisationSuccessMessage('');
		}, NOTIFICATION_DISPLAY_DURATION);
	}

	private showDetachingSuccessMessage(name: string): void {
		store.mutations.setOrganisationSuccessMessage(`<em>${name}</em> was successfully detached from this entity.`);
		setTimeout(() => {
			store.mutations.setOrganisationSuccessMessage('');
		}, NOTIFICATION_DISPLAY_DURATION);
	}
}

export default new UserPresenter();
