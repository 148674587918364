import { defineComponent } from 'vue';
import OrganisationSuccessMessageCode from '@/presentation/components/organisation-success-message/organisation-success-message.code';

export default defineComponent({
	setup: () => {
		const code = new OrganisationSuccessMessageCode();

		return {
			organisationSuccessMessage: code.organisationSuccessMessage
		};
	}
});
