import { Component, defineComponent, onMounted } from 'vue';
import OrganisationSuccessMessage from '@/presentation/components/organisation-success-message/organisation-success-message.vue';
import OrganisationDetailsBlock from '@/presentation/components/organisation-details-block/organisation-details-block.vue';
import OrganisationUsersTable from '@/presentation/components/organisation-users-table/organisation-users-table.vue';
import Accordion from '@/presentation/components/accordion/accordion.vue';
import UserDetailsForm from '@/presentation/components/user-details-form/user-details-form.vue';
import SearchInput from '@/presentation/components/search-input/search-input.vue';
import DeleteConfirmationModal from '@/presentation/components/delete-confirmation-modal/delete-confirmation-modal.vue';
import OrganisationCode from '@/presentation/pages/organisation/organisation.code';

export default defineComponent({
	components: {
		OrganisationSuccessMessage: OrganisationSuccessMessage as Component,
		OrganisationDetailsBlock: OrganisationDetailsBlock as Component,
		OrganisationUsersTable: OrganisationUsersTable as Component,
		Accordion: Accordion as Component,
		UserDetailsForm: UserDetailsForm as Component,
		SearchInput: SearchInput as Component,
		DeleteConfirmationModal: DeleteConfirmationModal as Component
	},
	setup: () => {
		const code = new OrganisationCode();

		onMounted(code.mounted.bind(code));

		return {
			searchTypeUser: code.searchTypeUser,
			isModalConfirmationStateDelete: code.isModalConfirmationStateDelete,
			amountOfUsersText: code.amountOfUsersText,
			isNewUserAccordionOpen: code.isNewUserAccordionOpen,
			toggleNewUserAccordion: code.toggleNewUserAccordion.bind(code),
			navigateToUserActionsPage: code.navigateToUserActionsPage.bind(code)
		};
	}
});
