<template>
	<div class="UsersManagementPageBlock">

		<OrganisationSuccessMessage />

		<button
			class="ButtonGoToUsersActions"
			@click="navigateToUserActionsPage"
		>
			<i class="lnr-arrow-left NavigationBackButtonIcon"></i>
			Return
		</button>

		<div class="UsersManagementContentBlock">
			<OrganisationDetailsBlock />

			<Accordion
				buttonText="Manage users"
				:isOpen="isNewUserAccordionOpen"
				@toggleAccordion="toggleNewUserAccordion"
			>
				<p class="UsersManagementText">
					Attach an <strong>existing user</strong> to this entity:
				</p>

				<div class="UserSearchBlockWrapper">
					<label class="UserSearchLabel">
						Search users
					</label>

					<SearchInput :type="searchTypeUser" />
				</div>


				<p class="UsersManagementText UsersManagementOptionsSeparator">
					or
				</p>

				<p class="UsersManagementText">
					Create a <strong>new user</strong> and attach to this entity:
				</p>

				<UserDetailsForm
					:isVisible="isNewUserAccordionOpen"
					:isEdit="false"
					@formClosed="toggleNewUserAccordion"
				/>
			</Accordion>

			<div class="UsersManagementSubtitle UsersCountText">
				The following <em class="TextHighlighted">{{ amountOfUsersText }}</em>
				have access to this entity
			</div>
			<OrganisationUsersTable />
		</div>

		<DeleteConfirmationModal v-if="isModalConfirmationStateDelete" />
	</div>
</template>

<style lang="scss" src="./organisation.scss"></style>
<script lang="ts" src="./organisation.ts"></script>
