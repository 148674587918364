import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "UsersManagementSuccessMessage"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.organisationSuccessMessage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("i", { class: "lnr-checkmark-circle UsersManagementSuccessMessageIcon" }, null, -1 /* HOISTED */)),
        _createElementVNode("span", {
          innerHTML: _ctx.organisationSuccessMessage,
          class: "UsersManagementSuccessMessageText"
        }, null, 8 /* PROPS */, _hoisted_2)
      ]))
    : _createCommentVNode("v-if", true)
}