<template>
	<div
		v-if="organisationSuccessMessage"
		class="UsersManagementSuccessMessage"
	>
		<i class="lnr-checkmark-circle UsersManagementSuccessMessageIcon"></i>
		<span
			v-html="organisationSuccessMessage"
			class="UsersManagementSuccessMessageText"
		></span>
	</div>
</template>

<style lang="scss" src="./organisation-success-message.scss"></style>
<script lang="ts" src="./organisation-success-message.ts"></script>
