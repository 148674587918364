<template>
	<div class="LogInWidget">
		<button
			class="ChampionButton SignInButton"
			@click="signInInternal()"
		>
			Sign in as internal user
		</button>
	</div>
</template>

<script lang="ts" src="./log-in.ts"></script>
<style lang="scss" src="./log-in.scss"></style>
