import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "UsersManagementPageBlock" }
const _hoisted_2 = { class: "UsersManagementContentBlock" }
const _hoisted_3 = {
  key: 0,
  class: "UsersManagementSubtitle UsersManagementActionsClarificationText"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_UserDetailsForm = _resolveComponent("UserDetailsForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "UsersManagementSubtitle UsersManagementActionsSubtitle" }, " You have the option to either visit the organisation page for user addition or editing, or you can directly add a new user using the form below: ", -1 /* HOISTED */)),
      _createVNode(_component_Accordion, {
        buttonText: "Find or edit an <strong>existing user</strong>",
        class: "UsersManagementElevatedContentBlock UserActionContentBlock",
        isOpen: _ctx.isFindOrEditUserAccordionOpen,
        onToggleAccordion: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setSelectedOption(_ctx.accordionSelectionOptionFindOrEditUser)))
      }, {
        default: _withCtx(() => [
          (_ctx.isFindOrEditUserAccordionOpen)
            ? (_openBlock(), _createBlock(_component_SearchInput, {
                key: 0,
                type: _ctx.searchTypeCombinedSearch
              }, null, 8 /* PROPS */, ["type"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["isOpen"]),
      _createVNode(_component_Accordion, {
        buttonText: "Create a <strong>new user</strong> and attach to an existing organisation",
        class: "UsersManagementElevatedContentBlock UserActionContentBlock",
        isOpen: _ctx.isAddNewUserAccordionOpen,
        onToggleAccordion: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setSelectedOption(_ctx.accordionSelectionOptionAddNewUser)))
      }, {
        default: _withCtx(() => [
          (_ctx.isAddNewUserAccordionOpen)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, " Please enter the organisation name or ID to add a user. "))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_UserDetailsForm, {
            isVisible: _ctx.isAddNewUserAccordionOpen,
            isEdit: false,
            onFormClosed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setSelectedOption(_ctx.accordionSelectionOptionAddNewUser))),
            onUserCreated: _ctx.navigateToOrganisationPage
          }, null, 8 /* PROPS */, ["isVisible", "onUserCreated"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["isOpen"])
    ])
  ]))
}