import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  ref: "modalContent",
  class: "ErrorModal"
}
const _hoisted_2 = { class: "ErrorDetails" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("p", null, [
      _createElementVNode("i", { class: "lnr-notification-circle ErrorModalIcon" })
    ], -1 /* HOISTED */)),
    _createElementVNode("p", _hoisted_2, [
      (_ctx.errorIsRelatedToOrganisationDetails)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(" The details of this organisation could not be fetched. ")
          ], 64 /* STABLE_FRAGMENT */))
        : (_ctx.errorIsRelatedToOrganisationUsers)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(" The users that are attached to this organisation could not be fetched. ")
            ], 64 /* STABLE_FRAGMENT */))
          : (_ctx.errorIsRelatedToCreateNewUser)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createTextVNode(" The user could not be created. ")
              ], 64 /* STABLE_FRAGMENT */))
            : (_ctx.errorIsRelatedToUpdateExistingUser)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                  _createTextVNode(" The user could not be updated. ")
                ], 64 /* STABLE_FRAGMENT */))
              : (_ctx.errorIsRelatedToTriggerOnboardingFlow)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                    _createTextVNode(" The onboarding flow could not be triggered. ")
                  ], 64 /* STABLE_FRAGMENT */))
                : (_ctx.errorIsRelatedToSearchUsers)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                      _createTextVNode(" The users that match the provided search query could not be fetched. ")
                    ], 64 /* STABLE_FRAGMENT */))
                  : (_ctx.errorIsRelatedToAttachUserToOrganisation)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                        _createTextVNode(" The user could not be attached to the organisation. ")
                      ], 64 /* STABLE_FRAGMENT */))
                    : (_ctx.errorIsRelatedToDeleteUser)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
                          _createTextVNode(" The user could not be deleted. ")
                        ], 64 /* STABLE_FRAGMENT */))
                      : (_ctx.errorIsRelatedToDetachUser)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 8 }, [
                            _createTextVNode(" The user could not be detached from the organisation. ")
                          ], 64 /* STABLE_FRAGMENT */))
                        : (_ctx.errorIsRelatedToSearchOrganisations || _ctx.errorIsRelatedToCombinedSearchUsers)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 9 }, [
                              _createTextVNode(" The organisations that match your search could not be fetched. ")
                            ], 64 /* STABLE_FRAGMENT */))
                          : _createCommentVNode("v-if", true),
      (_ctx.errorIsRelatedToCreateDuplicateUser)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 10 }, [
            _createTextVNode(" The user already exists. Search for this user instead. ")
          ], 64 /* STABLE_FRAGMENT */))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 11 }, [
            _createTextVNode(" Please reload the page. ")
          ], 64 /* STABLE_FRAGMENT */)),
      _cache[0] || (_cache[0] = _createTextVNode(" If the problem persists, please contact the Product Team. "))
    ])
  ], 512 /* NEED_PATCH */))
}