class CookieHandler {
	public retrieveTokenIdCookie(): string {
		const partsOfCompleteCookieString = document.cookie.split('hs-sso-token-id=');
		if (partsOfCompleteCookieString.length !== 2) {
			return '';
		}

		const remainingCookieParts = partsOfCompleteCookieString[1].split(';');
		return remainingCookieParts[0];
	}
}

export default new CookieHandler();
