import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "UsersManagementAccordion" }
const _hoisted_2 = { class: "NavigatorButton UsersManagementButtonIcon" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "UsersManagementButton",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleAccordion && _ctx.toggleAccordion(...args)))
    }, [
      _createElementVNode("span", _hoisted_2, [
        _createElementVNode("i", {
          class: _normalizeClass(_ctx.isAccordionOpen ? 'lnr-minus' : 'lnr-plus')
        }, null, 2 /* CLASS */)
      ]),
      _createElementVNode("span", {
        class: "UsersManagementButtonText",
        innerHTML: _ctx.buttonText
      }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([
			'UsersManagementAccordionContent',
			{ ContentVisible: _ctx.isAccordionOpen }
		])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2 /* CLASS */)
  ]))
}