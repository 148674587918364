<template>
	<div class="UsersManagementPageBlock">
		<div class="UsersManagementContentBlock">
			<p class="UsersManagementSubtitle UsersManagementActionsSubtitle">
				You have the option to either visit the organisation page for user addition or editing,
				or you can directly add a new user using the form below:
			</p>
			<Accordion
				buttonText="Find or edit an <strong>existing user</strong>"
				class="UsersManagementElevatedContentBlock UserActionContentBlock"
				:isOpen="isFindOrEditUserAccordionOpen"
				@toggleAccordion="setSelectedOption(accordionSelectionOptionFindOrEditUser)"
			>
				<SearchInput
					v-if="isFindOrEditUserAccordionOpen"
					:type="searchTypeCombinedSearch"
				/>
			</Accordion>
			<Accordion
				buttonText="Create a <strong>new user</strong> and attach to an existing organisation"
				class="UsersManagementElevatedContentBlock UserActionContentBlock"
				:isOpen="isAddNewUserAccordionOpen"
				@toggleAccordion="setSelectedOption(accordionSelectionOptionAddNewUser)"
			>
				<p
					class="UsersManagementSubtitle UsersManagementActionsClarificationText"
					v-if="isAddNewUserAccordionOpen"
				>
					Please enter the organisation name or ID to add a user.
				</p>
				<UserDetailsForm
					:isVisible="isAddNewUserAccordionOpen"
					:isEdit="false"
					@formClosed="setSelectedOption(accordionSelectionOptionAddNewUser)"
					@userCreated="navigateToOrganisationPage"
				/>
			</Accordion>
		</div>
	</div>
</template>

<style lang="scss" src="./user-actions.scss"></style>
<script lang="ts" src="./user-actions.ts"></script>
