import { computed, ref, Ref, watch } from 'vue';
import { SearchDropdownTypes } from '@/models/search.interface';
import { EmitOptions } from '@/models/emit-options.enum';
import { DetailedUserDto, MinimalOrganisationDto } from '@studyportals/campaign-management-api-interface';
import { IUserContactDetails, IUserRightsDetails, IUserContactDetailsUpdateEvent, UserContactDetailsTypes } from '@/models/user.interface';
import { IRouter, Routes } from '@/models/router/router.interface';
import userPresenter from '@/presenters/user-presenter';
import store from '@/store';

export default class UserDetailsFormCode {
	public validationMessage = ref('');
	// Bump a counter every time the form is reset, so that the form can be forced to re-render.
	public resetCount = ref(0);
	public selectedOrganisation: Ref<MinimalOrganisationDto | undefined> = ref(undefined);
	public savingProcessActive = ref(false);

	private adjustedFullName = ref('');
	private adjustedEmail = ref('');
	private adjustedRole = ref('');
	private adjustedHasTableauAccessSetting = ref(false);
	private adjustedHasERTAccessSetting = ref(false);
	private adjustedHasSMTAccessSetting = ref(false);
	private originalEmail = ref('');

	public get searchTypeOrganisation(): SearchDropdownTypes {
		return SearchDropdownTypes.ORGANISATION;
	}

	public nameOfSelectedOrganisation = computed((): string => {
		return this.selectedOrganisation.value?.name || '';
	});

	public shouldOrganisationSelectorBeShown = computed((): boolean => {
		return this.router.value.current === Routes.USER_ACTIONS;
	});

	public isSubmitDisabled = computed((): boolean => {
		if (this.savingProcessActive.value) {
			return true;
		}

		const invalidProperty = this.contactDetailsProperties.value.find(
			(property: IUserContactDetails) => property.required && !property.value
		);
		return invalidProperty !== undefined;
	});

	public contactDetailsProperties = computed((): IUserContactDetails[] => {
		return [this.contactDetailsPropertyFullName.value, this.contactDetailsPropertyEmail.value, this.contactDetailsPropertyRole.value];
	});

	public userRightsProperties = computed((): IUserRightsDetails[] => {
		return [this.userRightsPropertyTableau.value, this.userRightsPropertyERT.value, this.userRightsPropertySMT.value];
	});

	private contactDetailsPropertyFullName = computed((): IUserContactDetails => {
		return {
			label: 'Full name',
			type: UserContactDetailsTypes.TEXT,
			value: this.adjustedFullName.value,
			elementId: 'userFullName',
			required: true,
			updateMethod: this.setFullNameBasedOnEvent.bind(this)
		};
	});

	private contactDetailsPropertyEmail = computed((): IUserContactDetails => {
		return {
			label: 'Email (login)',
			type: UserContactDetailsTypes.EMAIL,
			value: this.adjustedEmail.value,
			elementId: 'userEmail',
			required: true,
			updateMethod: this.setEmailBasedOnEvent.bind(this)
		};
	});

	private contactDetailsPropertyRole = computed((): IUserContactDetails => {
		return {
			label: 'Role',
			type: UserContactDetailsTypes.TEXT,
			value: this.adjustedRole.value,
			elementId: 'userRole',
			required: false,
			updateMethod: this.setRoleBasedOnEvent.bind(this)
		};
	});

	private userRightsPropertyTableau = computed((): IUserRightsDetails => {
		return {
			labelChecked: 'This user has access to Tableau',
			labelUnchecked: 'This user does not have access to Tableau',
			value: this.adjustedHasTableauAccessSetting.value,
			toggleMethod: this.toggleHasTableauAccess.bind(this)
		};
	});

	private userRightsPropertyERT = computed((): IUserRightsDetails => {
		return {
			labelChecked: 'This user has access to ERT',
			labelUnchecked: 'This user does not have access to ERT',
			value: this.adjustedHasERTAccessSetting.value,
			toggleMethod: this.toggleHasERTAccess.bind(this)
		};
	});

	private userRightsPropertySMT = computed((): IUserRightsDetails => {
		return {
			labelChecked: 'This user has access to SMT',
			labelUnchecked: 'This user does not have access to SMT',
			value: this.adjustedHasSMTAccessSetting.value,
			toggleMethod: this.toggleHasSMTAccess.bind(this)
		};
	});

	private userFullName = computed((): string => {
		return this.user.value?.fullName || '';
	});

	private userEmail = computed((): string => {
		return this.user.value?.email || '';
	});

	private userRole = computed((): string => {
		return this.user.value?.role || '';
	});

	private userHasTableauAccess = computed((): boolean => {
		return this.user.value?.hasTableauAccess || false;
	});

	private userHasERTAccess = computed((): boolean => {
		return this.user.value?.hasERTAccess || false;
	});

	private userHasSMTAccess = computed((): boolean => {
		return this.user.value?.hasSMTAccess || false;
	});

	private router = computed((): IRouter => {
		return store.getters.router();
	});

	constructor(
		private emit: (name: string, id?: number) => void,
		private isEdit: boolean,
		private user: Ref<DetailedUserDto | undefined>
	) {
		this.setOriginalEmailBasedOnSelectedUser();
	}

	public onFormVisibleUpdateFormValues(isVisible: boolean): void {
		if (!isVisible) {
			return;
		}

		this.updateFormValues();
	}

	public async saveUser(): Promise<void> {
		if (!this.checkValidation()) {
			return;
		}

		this.savingProcessActive.value = true;
		if (this.isEdit) {
			await this.updateExistingUser();
		} else {
			await this.createNewUser();
		}
		this.savingProcessActive.value = false;

		this.updateFormValues(true);
	}

	public updateFormValues(isReset = false): void {
		this.adjustedFullName.value = isReset ? '' : this.userFullName.value;
		this.adjustedEmail.value = isReset ? '' : this.userEmail.value;
		this.adjustedRole.value = isReset ? '' : this.userRole.value;

		this.adjustedHasTableauAccessSetting.value = isReset ? false : this.userHasTableauAccess.value;
		this.adjustedHasERTAccessSetting.value = isReset ? false : this.userHasERTAccess.value;
		this.adjustedHasSMTAccessSetting.value = isReset ? false : this.userHasSMTAccess.value;

		if (!isReset) {
			return;
		}

		this.validationMessage.value = '';
		this.resetCount.value++;

		this.emit(EmitOptions.FORM_CLOSED);
	}

	public setSelectedOrganisation(selectedValue: MinimalOrganisationDto): void {
		this.selectedOrganisation.value = selectedValue;
	}

	private setOriginalEmailBasedOnSelectedUser(): void {
		watch(this.user, (newValue, oldValue) => {
			this.resetCount.value++;
			if (typeof newValue?.email !== 'string' || typeof oldValue?.email !== 'undefined') {
				return;
			}

			this.originalEmail.value = newValue.email;
		});
	}

	private async createNewUser(): Promise<void> {
		let organisationId: number | undefined;
		if (this.selectedOrganisation.value) {
			organisationId = this.selectedOrganisation.value.id;
		}

		await userPresenter.createNewUser(
			{
				fullName: this.adjustedFullName.value,
				email: this.adjustedEmail.value,
				role: this.adjustedRole.value,
				hasTableauAccess: this.adjustedHasTableauAccessSetting.value,
				hasERTAccess: this.adjustedHasERTAccessSetting.value,
				hasSMTAccess: this.adjustedHasSMTAccessSetting.value
			},
			organisationId
		);

		// Unselect the user just in case; the position where the user details are shown might not be correct anymore after the change.
		store.mutations.setSelectedUser(0);

		if (!this.selectedOrganisation.value) {
			return;
		}

		this.emit(EmitOptions.USER_CREATED, this.selectedOrganisation.value.id);
	}

	private async updateExistingUser(): Promise<void> {
		await userPresenter.updateExistingUser({
			id: this.user.value?.id || 0,
			fullName: this.adjustedFullName.value,
			email: this.adjustedEmail.value,
			originalEmail: this.originalEmail.value,
			role: this.adjustedRole.value,
			hasTableauAccess: this.adjustedHasTableauAccessSetting.value,
			hasERTAccess: this.adjustedHasERTAccessSetting.value,
			hasSMTAccess: this.adjustedHasSMTAccessSetting.value
		});
	}

	private checkValidation(): boolean {
		this.validationMessage.value = '';

		if (!this.adjustedFullName.value) {
			this.validationMessage.value = 'No full name was provided.';
		} else if (!this.adjustedEmail.value) {
			this.validationMessage.value = 'No email address was provided.';
		} else if (!this.isEmail(this.adjustedEmail.value)) {
			this.validationMessage.value = 'The provided email address is not valid.';
		} else if (this.shouldOrganisationSelectorBeShown.value && !this.selectedOrganisation.value) {
			this.validationMessage.value = 'No organisation was selected.';
		}

		return !this.validationMessage.value;
	}

	private setFullNameBasedOnEvent(event: IUserContactDetailsUpdateEvent): void {
		const value = event?.target?.value;
		if (value === undefined) {
			return;
		}

		this.adjustedFullName.value = value;
	}

	private setEmailBasedOnEvent(event: IUserContactDetailsUpdateEvent): void {
		const value = event?.target?.value;
		if (value === undefined) {
			return;
		}

		this.adjustedEmail.value = value;
	}

	private setRoleBasedOnEvent(event: IUserContactDetailsUpdateEvent): void {
		const value = event?.target?.value;
		if (value === undefined) {
			return;
		}

		this.adjustedRole.value = value;
	}

	private toggleHasTableauAccess(): void {
		this.adjustedHasTableauAccessSetting.value = !this.adjustedHasTableauAccessSetting.value;
	}

	private toggleHasERTAccess(): void {
		this.adjustedHasERTAccessSetting.value = !this.adjustedHasERTAccessSetting.value;
	}

	private toggleHasSMTAccess(): void {
		this.adjustedHasSMTAccessSetting.value = !this.adjustedHasSMTAccessSetting.value;
	}

	public isEmail(value: string): boolean {
		// eslint-disable-next-line max-len
		const regex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(value);
	}
}
