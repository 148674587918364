import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "UsersManagementPageBlock" }
const _hoisted_2 = { class: "UsersManagementContentBlock" }
const _hoisted_3 = { class: "UserSearchBlockWrapper" }
const _hoisted_4 = { class: "UsersManagementSubtitle UsersCountText" }
const _hoisted_5 = { class: "TextHighlighted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrganisationSuccessMessage = _resolveComponent("OrganisationSuccessMessage")!
  const _component_OrganisationDetailsBlock = _resolveComponent("OrganisationDetailsBlock")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_UserDetailsForm = _resolveComponent("UserDetailsForm")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_OrganisationUsersTable = _resolveComponent("OrganisationUsersTable")!
  const _component_DeleteConfirmationModal = _resolveComponent("DeleteConfirmationModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_OrganisationSuccessMessage),
    _createElementVNode("button", {
      class: "ButtonGoToUsersActions",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigateToUserActionsPage && _ctx.navigateToUserActionsPage(...args)))
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("i", { class: "lnr-arrow-left NavigationBackButtonIcon" }, null, -1 /* HOISTED */),
      _createTextVNode(" Return ")
    ])),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_OrganisationDetailsBlock),
      _createVNode(_component_Accordion, {
        buttonText: "Manage users",
        isOpen: _ctx.isNewUserAccordionOpen,
        onToggleAccordion: _ctx.toggleNewUserAccordion
      }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createElementVNode("p", { class: "UsersManagementText" }, [
            _createTextVNode(" Attach an "),
            _createElementVNode("strong", null, "existing user"),
            _createTextVNode(" to this entity: ")
          ], -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_3, [
            _cache[2] || (_cache[2] = _createElementVNode("label", { class: "UserSearchLabel" }, " Search users ", -1 /* HOISTED */)),
            _createVNode(_component_SearchInput, { type: _ctx.searchTypeUser }, null, 8 /* PROPS */, ["type"])
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("p", { class: "UsersManagementText UsersManagementOptionsSeparator" }, " or ", -1 /* HOISTED */)),
          _cache[5] || (_cache[5] = _createElementVNode("p", { class: "UsersManagementText" }, [
            _createTextVNode(" Create a "),
            _createElementVNode("strong", null, "new user"),
            _createTextVNode(" and attach to this entity: ")
          ], -1 /* HOISTED */)),
          _createVNode(_component_UserDetailsForm, {
            isVisible: _ctx.isNewUserAccordionOpen,
            isEdit: false,
            onFormClosed: _ctx.toggleNewUserAccordion
          }, null, 8 /* PROPS */, ["isVisible", "onFormClosed"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["isOpen", "onToggleAccordion"]),
      _createElementVNode("div", _hoisted_4, [
        _cache[6] || (_cache[6] = _createTextVNode(" The following ")),
        _createElementVNode("em", _hoisted_5, _toDisplayString(_ctx.amountOfUsersText), 1 /* TEXT */),
        _cache[7] || (_cache[7] = _createTextVNode(" have access to this entity "))
      ]),
      _createVNode(_component_OrganisationUsersTable)
    ]),
    (_ctx.isModalConfirmationStateDelete)
      ? (_openBlock(), _createBlock(_component_DeleteConfirmationModal, { key: 0 }))
      : _createCommentVNode("v-if", true)
  ]))
}