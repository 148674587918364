import { CredentialsLifetimeManager } from '@studyportals/client-internal-platform-aws-authorization';
import { IUserSessionOperationsProvider, UserSessionCookieManager } from '@studyportals/client-internal-platform-sso';

class UserSession {
	public readonly userSessionOperations: IUserSessionOperationsProvider;
	public readonly credentialsLifetimeManager: CredentialsLifetimeManager;

	public constructor() {
		const domain = webpackDefinitions.VUE_APP_DOMAIN;

		const cognitoClientId = webpackDefinitions.VUE_APP_COGNITO_CLIENT_ID;
		const cognitoUserPoolId = webpackDefinitions.VUE_APP_COGNITO_USER_POOL_ID;
		const cognitoUserPoolRegion = webpackDefinitions.VUE_APP_COGNITO_USER_POOL_REGION;

		const cognitoIdentityPoolId = webpackDefinitions.VUE_APP_COGNITO_IDENTITY_POOL_ID;
		const cognitoIdentityPoolRegion = webpackDefinitions.VUE_APP_COGNITO_IDENTITY_POOL_REGION;

		this.userSessionOperations = new UserSessionCookieManager(cognitoClientId, cognitoUserPoolId, domain);

		this.credentialsLifetimeManager = new CredentialsLifetimeManager(
			this.userSessionOperations,
			cognitoIdentityPoolRegion,
			cognitoIdentityPoolId,
			cognitoUserPoolRegion,
			cognitoUserPoolId
		);
	}

	public async acquireCredentials(): Promise<void> {
		return await this.credentialsLifetimeManager.acquireCredentials();
	}

	public isValid(): boolean {
		return this.userSessionOperations.isSessionSetupAndNotExpired();
	}
}

const userSession = new UserSession();
export default userSession;
