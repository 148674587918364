enum ModalErrorStates {
	INACTIVE = 0,
	LOADING_ORGANISATION_DETAILS = 1,
	LOADING_ORGANISATION_USERS = 2,
	CREATE_NEW_USER = 3,
	UPDATE_EXISTING_USER = 4,
	TRIGGER_ONBOARDING_FLOW = 5,
	SEARCH_USERS = 6,
	ATTACH_USER_TO_ORGANISATION = 7,
	DELETE_USER = 8,
	DETACH_USER = 9,
	SEARCH_ORGANISATIONS = 10,
	COMBINED_SEARCH_USERS = 11,
	CREATE_DUPLICATE_USER = 12
}

enum ModalConfirmationStates {
	INACTIVE = 0,
	DELETE_USER = 1
}

export { ModalErrorStates, ModalConfirmationStates };
