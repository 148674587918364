import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "OrganisationDetailsBlock"
}
const _hoisted_2 = { class: "UsersManagementTitle" }
const _hoisted_3 = { class: "UsersManagementIdBlock" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.organisationName)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.organisationName), 1 /* TEXT */),
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "UsersManagementLabel" }, " Organisation ID: ", -1 /* HOISTED */)),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.organisationId), 1 /* TEXT */)
      ]))
    : _createCommentVNode("v-if", true)
}