<template>
	<div
		:class="[
			'SearchContainer',
			{ SpecificSearchContainer: isSearchTypeUser || isSearchTypeOrganisation },
			{ CombinedSearchContainer: isSearchTypeCombinedSearch }
		]"
	>
		<span
			v-if="isSearchTypeCombinedSearch"
			class="SearchIcon"
		>
			<i class="lnr-magnifier"></i>
		</span>
		<div
			class="UserSearchWrapper"
			ref="searchWrapper"
		>
			<input
				type="text"
				class="SearchInput"
				ref="searchInput"
				:placeholder="placeholder"
				@input="setSearchTerm"
				@focus="onInputFocus"
			/>
			<SearchDropdown
				v-if="showDropdown"
				:key="searchResultsAsString"
				:type="type"
				:searchTerm="searchTerm"
				:items="searchResults"
				:showSpinner="showSpinner"
				@userClick="onUserClick"
			/>
		</div>
	</div>
</template>

<script lang="ts" src="./search-input.ts" />
<style lang="scss" src="./search-input.scss" />
