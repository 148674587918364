import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "UserContactDetailsWrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["for"]
const _hoisted_4 = ["type", "id", "required", "value", "onInput"]
const _hoisted_5 = {
  key: 0,
  class: "ValidationMessage"
}
const _hoisted_6 = { class: "UserRightsDetailsWrapper" }
const _hoisted_7 = { class: "UserRightsDetailsRow" }
const _hoisted_8 = ["checked"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["data-on", "data-off"]
const _hoisted_11 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!

  return (_openBlock(), _createElementBlock("form", {
    class: "UserDetailsForm ToggleBase",
    key: `userDetailsForm_${_ctx.resetCount}`
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.shouldOrganisationSelectorBeShown)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[2] || (_cache[2] = _createElementVNode("label", { class: "UserContactDetailsLabel" }, " Organisation ", -1 /* HOISTED */)),
            _createVNode(_component_SearchInput, {
              type: _ctx.searchTypeOrganisation,
              onValueSelected: _ctx.setSelectedOrganisation
            }, null, 8 /* PROPS */, ["type", "onValueSelected"])
          ]))
        : _createCommentVNode("v-if", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactDetailsProperties, (property) => {
        return (_openBlock(), _createElementBlock("div", null, [
          _createElementVNode("label", {
            class: "UserContactDetailsLabel",
            for: property.elementId
          }, _toDisplayString(property.label), 9 /* TEXT, PROPS */, _hoisted_3),
          _createElementVNode("input", {
            class: "UserContactDetailsRow",
            type: property.type,
            id: property.elementId,
            required: property.required,
            value: property.value,
            onInput: property.updateMethod
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4)
        ]))
      }), 256 /* UNKEYED_FRAGMENT */))
    ]),
    (_ctx.validationMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.validationMessage), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userRightsProperties, (property) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("input", {
            type: "checkbox",
            class: "Toggle ToggleLight",
            checked: property.value
          }, null, 8 /* PROPS */, _hoisted_8),
          _createElementVNode("label", {
            class: "ToggleButton",
            onClick: property.toggleMethod
          }, null, 8 /* PROPS */, _hoisted_9),
          _createElementVNode("span", {
            class: "ToggleStatus UserRightsDetailsLabel",
            "data-on": property.labelChecked,
            "data-off": property.labelUnchecked
          }, "   ", 8 /* PROPS */, _hoisted_10)
        ]))
      }), 256 /* UNKEYED_FRAGMENT */))
    ]),
    _createElementVNode("input", {
      type: "button",
      class: "ChampionButton",
      value: _ctx.isEdit ? 'Save Changes' : 'Create',
      disabled: _ctx.isSubmitDisabled,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.saveUser && _ctx.saveUser(...args)))
    }, null, 8 /* PROPS */, _hoisted_11),
    _createElementVNode("button", {
      type: "button",
      class: "HelperButton UsersManagementHelperButton",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateFormValues(true)))
    }, " Cancel ")
  ]))
}